



.nav-item .pcoded-menu-option {
  color: white;
}

@media screen and (min-width: 992px) {
  header.navbar.pcoded-header.navbar-expand-lg.header-default {
    display: none;
  }
}

/* span beside the events menu */
span.label.pcoded-badge.label-danger {
  background-color: rgb(192, 83, 44);
}

.navbar-wrapper {
  background: rgb(14, 77, 41);
  background: linear-gradient(
    45deg,
    rgba(14, 77, 41, 1) 0%,
    rgba(22, 27, 87, 1) 100%
  );
}

.dataTables_wrapper table.dataTable td,
.dataTables_wrapper table.dataTable th {
  vertical-align: middle;
}

a.nav-link {
  transition: 0.4s;
}

a.nav-link:hover {
  background: rgba(255, 255, 255, 0.1);
  transform: translate(10px);
}

.pcoded-navbar .pcoded-inner-navbar li.pcoded-menu-caption {
  padding: 5px 20px 5px;
}

/* mobile header  */
header.navbar.pcoded-header.navbar-expand-lg.header-default {
}

