.add-new-transaction {
  display: flex;
  align-items: center;
  text-align: center;
  transition: 0.4s;
  justify-content: right;
  width: 100%;
}

.add-new-transaction div {
  margin-left: auto;
  display: flex;
}

.add-new-transaction .icon {
  padding: 18px;
  border-radius: 100%;
  background: #53e69d;
  color: white;

  cursor: pointer;
}

.add-new-transaction p {
  cursor: pointer;
  margin-top: 13px;
  color: black;
  padding-left: 10px;
}
.add-new-transaction p:hover {
  color: #2cabe3;
}
